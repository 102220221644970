var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"width":"600px","fullscreen":_vm.$vuetify.breakpoint.mobile},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_c('v-card',[_c('div',{staticStyle:{"display":"flex"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.snackbar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('div',{style:(("width: " + _vm.width + "px; height: " + (_vm.$vuetify.breakpoint.mobile ? _vm.width + 150 : _vm.width + 40) + "px; padding: 10px"))},[_c('div',{ref:"chart_div",style:(("width: " + (_vm.width - 20) + "px; height: " + (_vm.$vuetify.breakpoint.mobile ? _vm.width + 60 : _vm.width - 80) + "px"))}),(_vm.item)?_c('div',{staticClass:"dialog",style:(("width: " + (_vm.width - 20) + "px; padding-left: 20px"))},[_c('p',[_c('span',[_vm._v("平均达成率："+_vm._s(_vm.chart_stat.avg.toFixed(2))+"% (")]),_c('span',{style:(_vm.chart_stat.avg - _vm.diff_stat.achievements >= 0
                  ? 'color: #4CAF50'
                  : 'color: #F44336')},[_vm._v(_vm._s(_vm.chart_stat.avg - _vm.diff_stat.achievements >= 0 ? "+" : "")+_vm._s((_vm.chart_stat.avg - _vm.diff_stat.achievements).toFixed(2))+"%")]),_c('span',[_vm._v(")")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,351016999)},[_c('span',[_vm._v("括号内为此谱面与难度为 "+_vm._s(_vm.item.level)+" 的谱面达成率平均值的差")])])],1),_c('p',[_vm._v("平均 DX 分数："+_vm._s(_vm.chart_stat.avg_dx.toFixed(1)))]),_c('p',[_vm._v(" 谱面成绩标准差： "),(_vm.chart_stat.std_dev < 3.6)?_c('span',[_vm._v("正常")]):(_vm.chart_stat.std_dev < 4.2)?_c('span',{staticStyle:{"color":"#ffc107"}},[_vm._v("较高")]):(_vm.chart_stat.std_dev < 4.8)?_c('span',{staticStyle:{"color":"#ff9800"}},[_vm._v("高")]):_c('span',{staticStyle:{"color":"#f44336"}},[_vm._v("极高")]),_c('span',[_vm._v(" ("+_vm._s(_vm.chart_stat.std_dev.toFixed(2))+")")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"grey lighten-1"}},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle")])]}}],null,false,351016999)},[_c('span',[_vm._v("标准差越大，成绩分布越分散，可能说明是个人差曲或者越级较多（只统计距离平均成绩差距低于 10% 的成绩）")])])],1)]):_vm._e()])])],1),_c('v-data-table',{attrs:{"items-per-page":_vm.limit,"footer-props":{ 'items-per-page-options': [_vm.limit, -1] },"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"search":_vm.search,"sort-by":"rank","custom-filter":_vm.chartFilter,"no-data-text":"没有数据","loading-text":"加载中……","no-results-text":"没有符合条件的条目"},scopedSlots:_vm._u([{key:"item.cover",fn:function(ref){
                  var item = ref.item;
return [_c('v-img',{staticClass:"rounded",attrs:{"src":("https://www.diving-fish.com/covers/" + (_vm.getCoverPathById(
          item.song_id
        ))),"width":"72px","contain":""}})]}},{key:"item.title",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.music_data_dict[item.song_id]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.type == 'DX')?_c('a',{staticStyle:{"cursor":"default"},attrs:{"dark":"","color":"blue"}},[_vm._v("DX")]):_vm._e(),_vm._v(" "+_vm._s(item.title)+" "),(item.fc)?_c('v-chip',{attrs:{"color":_vm.getFC(item.fc),"dark":""}},[_vm._v(_vm._s(_vm.getName(item.fc)))]):_vm._e(),(item.fs)?_c('v-chip',{attrs:{"color":_vm.getFS(item.fs),"dark":""}},[_vm._v(_vm._s(_vm.getName(item.fs)))]):_vm._e()],1)]}}],null,true)},[(_vm.music_data_dict[item.song_id])?_c('span',[_vm._v(" id: "+_vm._s(_vm.music_data_dict[item.song_id].id)+" "),_c('br'),_vm._v(" Artist: "+_vm._s(_vm.music_data_dict[item.song_id].basic_info.artist)+" "),_c('br'),_vm._v(" Version: "+_vm._s(_vm.music_data_dict[item.song_id].basic_info.from)+" "),_c('br'),_vm._v(" Genre: "+_vm._s(_vm.music_data_dict[item.song_id].basic_info.genre)+" "),_c('br'),_vm._v(" BPM: "+_vm._s(_vm.music_data_dict[item.song_id].basic_info.bpm)+" "),_c('br')]):_vm._e()])]}},{key:"item.level",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.music_data_dict[item.song_id]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getLevel(item.level_index),"dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.level_label)+" "+_vm._s(item.level)+" ")])]}}],null,true)},[(_vm.music_data_dict[item.song_id])?_c('span',[_vm._v(" Charter: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].charter)+" "),_c('br'),_vm._v(" Tap: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[0])+" "),_c('br'),_vm._v(" Hold: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[1])+" "),_c('br'),_vm._v(" Slide: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[2])+" "),_c('br'),(_vm.music_data_dict[item.song_id].type == 'DX')?_c('span',[_vm._v(" Touch: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[3])+" "),_c('br'),_vm._v(" Break: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[4])+" "),_c('br')]):_c('span',[_vm._v(" Break: "+_vm._s(_vm.music_data_dict[item.song_id].charts[item.level_index].notes[3])+" "),_c('br')])]):_vm._e()])]}},{key:"item.achievements",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.achievements.toFixed(4))+"% "),_c('v-chip',{staticClass:"ml-1",attrs:{"color":_vm.getRate(item.rate),"outlined":""}},[_vm._v(_vm._s(item.rate.replace("p", "+").toUpperCase()))])]}},{key:"item.ra",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.rank <= _vm.limit)?_c('span',_vm._g(_vm._b({staticStyle:{"color":"#4caf50"}},'span',attrs,false),on),[_vm._v(_vm._s(item.ra))]):_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.ra))])]}}],null,true)},[(!_vm.getMoreRa(item).length)?_c('span',[_vm._v("已达成本曲最高DX rating。")]):_vm._l((_vm.getMoreRa(item)),function(j,key){return _c('span',{key:key},[_vm._v(" "+_vm._s(j.ra)+"(+"+_vm._s(j.ra - item.ra)+")："+_vm._s(j.achievements.toFixed(4))+"(+"+_vm._s((j.achievements - item.achievements).toFixed(4))+")"),_c('br')])})],2)]}},{key:"item.fit_diff",fn:function(ref){
        var item = ref.item;
return [(_vm.chart_stats.charts[item.song_id] && _vm.chart_stats.charts[item.song_id][item.level_index])?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.showChart(item)}}},'span',attrs,false),on),[_vm._v(_vm._s(item.fit_diff.toFixed(2)))])]}}],null,true)},[_vm._v(" 点击以查看该谱面的统计信息 ")]):_c('span',{staticStyle:{"cursor":"pointer","color":"#f44336"}},[_vm._v(_vm._s(item.ds.toFixed(2)))])]}},{key:"header.fit_diff",fn:function(){return [_vm._v(" 拟合难度 ")]},proxy:true},{key:"item.dxScore",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dxScore)+" ")]}},{key:"item.dxScore_perc",fn:function(ref){
        var item = ref.item;
return [(item.dxScore)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(item.dxScore_perc.toFixed(2))+"% "),(item.dxScore_perc >= 85)?_c('v-chip',{staticClass:"ml-1",attrs:{"color":_vm.getDXScore(item).color,"outlined":""}},[_vm._v("☆"+_vm._s(_vm.getDXScore(item).star))]):_vm._e()],1)]}}],null,true)},[_vm._v(" DX分数比例为 "+_vm._s(item.dxScore)+"/"+_vm._s(_vm.getDXScore(item).total)),_c('br'),(item.dxScore_perc < 97)?_c('span',[_vm._v(" 距离下一个星级（☆"+_vm._s(_vm.getDXScore(item).star + 1)+"，"+_vm._s(_vm.getDXScore(item).next)+"）还差"+_vm._s(_vm.getDXScore(item).next - item.dxScore)+"分")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(item.dxScore_perc.toFixed(2))+"% ")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.cover(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-image-outline")])]}}],null,true)},[_vm._v(" 查看封面 ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.modify(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-pencil-box-outline")])]}}],null,true)},[_vm._v(" 编辑分数 ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":function($event){return _vm.calculator(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-calculator-variant-outline")])]}}],null,true)},[_vm._v(" 填入计算器 ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }