var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items-per-page":40,"footer-props":{ 'items-per-page-options': [40, -1] },"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"search":_vm.search,"sort-by":"rank","no-data-text":"没有数据","loading-text":"加载中……","no-results-text":"没有符合条件的条目"},scopedSlots:_vm._u([{key:"item.rank",fn:function(ref){
var item = ref.item;
return [(item.rank < 0)?_c('span',{staticStyle:{"color":"#00BCD4"}},[_vm._v("R"+_vm._s(item.rank + 11))]):_c('span',[_vm._v(_vm._s(item.rank))])]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.music_data_dict[item.mid]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" "),(item.fc)?_c('v-chip',{attrs:{"color":_vm.getFC(item.fc),"dark":""}},[_vm._v(_vm._s(_vm.getName(item.fc)))]):_vm._e()],1)]}}],null,true)},[(_vm.music_data_dict[item.mid])?_c('span',[_vm._v(" id: "+_vm._s(_vm.music_data_dict[item.mid].id)+" "),_c('br'),_vm._v(" Artist: "+_vm._s(_vm.music_data_dict[item.mid].basic_info.artist)+" "),_c('br'),_vm._v(" Version: "+_vm._s(_vm.music_data_dict[item.mid].basic_info.from)+" "),_c('br'),_vm._v(" Genre: "+_vm._s(_vm.music_data_dict[item.mid].basic_info.genre)+" "),_c('br'),_vm._v(" BPM: "+_vm._s(_vm.music_data_dict[item.mid].basic_info.bpm)+" "),_c('br')]):_vm._e()])]}},{key:"item.level",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.music_data_dict[item.mid]},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"color":_vm.getLevel(item.level_index),"dark":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(item.level_label)+" "+_vm._s(item.level)+" ")])]}}],null,true)},[(_vm.music_data_dict[item.mid])?_c('span',[_vm._v(" Charter: "+_vm._s(_vm.music_data_dict[item.mid].charts[item.level_index].charter)+" "),_c('br'),_vm._v(" Combo: "+_vm._s(_vm.music_data_dict[item.mid].charts[item.level_index].combo)+" "),_c('br')]):_vm._e()])]}},{key:"item.score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.score)+" "),_c('v-chip',{staticClass:"ml-1",attrs:{"color":_vm.getRateColor(_vm.getRate(item.score)),"outlined":""}},[_vm._v(_vm._s(_vm.getRate(item.score).toUpperCase()))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }