<template>
  <v-app>
    <main-page></main-page>
  </v-app>
</template>

<script>
import MainPage from './pages/MainPage.vue'
export default {
  components: { MainPage },
}
</script>

<style>

</style>