<template>
  <v-dialog
    v-model="visible"
    :width="900"
    :fullscreen="$vuetify.breakpoint.mobile"
  >
    <template #activator="{ on, attrs }">
      <v-btn class="ml-4" color="secondary" v-bind="attrs" v-on="on"
        >查分器网页使用指南</v-btn
      >
    </template>
    <v-card>
      <v-card-title>
        查分器网页使用指南
        <v-spacer />
        <v-btn icon @click="visible = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <p>
          某酱发现自己加了好多功能，但是大家都不会用，所以写了本教程来介绍使用本网页的所有细节。通读本教程能够使您更有效地使用本网页。
        </p>
        <h2 class="text--primary">“登录并同步数据”按钮</h2>
        <p>
          <b>注册新账号在这个按钮里面。</b>
        </p>
        <h2 class="text--primary">“导入数据”按钮</h2>
        <p>
          仅在使用方法二导入数据时使用。粘贴舞萌DX网页指定页面的源代码即可导入数据。
        </p>
        <h2 class="text--primary">“提交反馈”按钮</h2>
        <p>
          如果在使用本网页时出现了问题，或者对查分器有任何建议，可以点击按钮提交反馈给网页开发者。
        </p>
        <h2 class="text--primary">“导出为CSV”按钮</h2>
        <p>
          如果本网页的数据处理不能使你满意，可以点击导出为CSV并使用Excel等软件打开处理。
        </p>
        <h2 class="text--primary">“牌子查询”按钮</h2>
        <p>
          登录账号或导入数据后，可以点击按钮查询部分版本的Basic~Master制霸情况。
        </p>
        <h2 class="text--primary">“计算工具”按钮</h2>
        <p>
          点击本按钮后，可以在弹出的界面内进行一些maimai相关的实用计算。可以通过成绩表格中“编辑”一列中的计算器<v-icon
            >mdi-calculator-variant-outline</v-icon
          >
          按钮快速填入相应谱面的数据。在使用计算器<v-icon
            >mdi-calculator-variant-outline</v-icon
          >
          按钮填入数据后，界面底栏将显示相应的曲名及难度。当你手动修改这些数据时，界面底栏将消失。
        </p>
        <h3 class="text--primary">分数线计算</h3>
        <p>
          点击计算器<v-icon>mdi-calculator-variant-outline</v-icon>
          按钮或手动填入谱面的音符数量后，下方表格中将会显示每种音符及判定的得/失分数。左上角的“分数模式”选择栏中可以选择实机中的0+、100-、101-三种分数模式。其中Break音符中PERFECT列中三行分别为CRITICAL
          PERFECT、50落PERFECT（2550/75%奖分）、100落PERFECT（2500/50%奖分）；GREAT列中三行分别为2000、1500、1250三种判定（奖分均为40%）；GOOD为1000分，奖分为30%。
        </p>
        <p>
          填入音符数量后，可以在“目标达成率”填入想要达成的分数，在右侧查看计算出的容许Tap
          GREAT数。
        </p>
        <h3 class="text--primary">绝赞分布计算</h3>
        <p>
          本工具为一些“打出了一个成绩，但无法理解判定和达成率关系”的好奇宝宝准备，比如“为什么我就粉了一个绝赞绿了一条星星就100.6了”之类。（或者只是想得到成绩的更多小数位。）本工具将计算分数中绝赞（绝赞小P和粉绝赞）内的隐藏分数分布情况（如：你粉的绝赞其实是1250）。需要在上方“目标达成率”中填写实际达成率，并在下方填写完整判定表。
        </p>
        <p>
          如果使用计算器<v-icon>mdi-calculator-variant-outline</v-icon>
          按钮快速填入谱面数据，便可以使用默认的自动填充功能，即：只需填充所有音符的GOOD~MISS数，以及Break的PERFECT数，剩余音符数便会自动计算出并填充。由于除了Break音符外的所有音符的CRITICAL
          PERFECT与PERFECT达成率没有区别，这些剩余音符将全部填入CRITICAL
          PERFECT。
        </p>
        <p>
          你也可以通过勾选“手动填充所有栏”按钮来手动填写所有音符判定数。请注意如果这样填写，上方的音符总数将会随着输入的判定表中每行的和改变。
        </p>
        <p>
          填写完“目标达成率”和判定表后，点击“计算可能的绝赞分布”，下方表格中将显示接近目标达成率的可能的绝赞分布。注意为了避免浮点误差，所有与目标达成率相差小于0.0001%的情况均会显示。
        </p>
        <p>
          如果“目标达成率”一栏留空，下方表格中将显示给定判定条件下的所有可能的分数。注意在某些情况下，这个表格可能会很长。
        </p>
        <h3 class="text--primary">rating线计算</h3>
        <p>
          在这里，根据选择的模式不同，你可以：按给定的定数计算不同单曲rating的分界线；按给定的达成率计算所有定数的rating；以及按给定的rating计算最低需要达成的成绩。
        </p>
        <p>
          使用计算器<v-icon>mdi-calculator-variant-outline</v-icon>
          按钮也可以快速填入相应谱面的定数/达成率/rating。
        </p>
        <h2 class="text--primary">“解锁全曲”按钮</h2>
        <p>
          查分器默认仅显示曾经游玩过的谱面成绩。点击此按钮后，可以解锁所有未游玩过的谱面，使得你可以查看这些谱面的信息。注意：解锁全曲并不会保存到账号，这意味着每次重新打开查分器网页，如果有需求，都要重新点击一次此按钮。
        </p>
        <p>
          冷知识：解锁全曲后的查分器网页可以当作定数表使用。<b>实际上，现在的查分器网页可以代替mai-bot（千雪/Chiyuki）实现除了随歌、查封面、生成b40图片以外的所有功能</b>。
        </p>
        <h2 class="text--primary">“编辑个人资料”按钮</h2>
        <p>
          这里的设置基本都是服务于mai-bot的生成b40图片功能，特别是这里的“昵称”一栏数据仅仅作为b40图片中的昵称显示，请与查分器用户名相区分。
        </p>
        <p>
          “牌子设置”中，可以领取任何已经“确定”的牌子（即紫谱制霸）。此设置被一部分支持的bot使用，用于生成带牌子的b40图片，设置为“无”则不带牌子。
        </p>
        <h2 class="text--primary">成绩表格设置</h2>
        <h3 class="text--primary">“查找乐曲”搜索框</h3>
        <p>
          在这里输入文本可以查找符合条件的谱面。可以搜索的内容包括：曲名、id、曲师、bpm、谱师、rating、定数。可以使用“^”字符匹配开头、“$”字符匹配结尾（如用“^Future$”匹配《Future》并排除《Catch
          The
          Future》）。更特殊地，可以分别使用“id”、“bpm”匹配id和bpm的开头（如用“id573”匹配《四月の雨》、“bpm271$”匹配《プナイプナイせんそう》）。
        </p>
        <h3 class="text--primary">等级/定数筛选</h3>
        <p>
          使用搜索框下的范围选择滑条可以限制成绩表格中显示条目的等级/定数范围。点击右侧“使用定数筛选”复选框以在等级/定数之间切换。解锁全曲后将定数限制到一个固定值就可以实现“定数查歌”。
        </p>
        <h3 class="text--primary">高级设置</h3>
        <p>
          在这里你可以进行一些复杂的设置，勾选“使用高级设置”复选框后显示。请注意如果取消勾选“使用高级设置”，所有高级设置将会恢复默认设置。
        </p>
        <p>
          首先是四种筛选，点击右侧的色块可以选择过滤对应的条目。可以点击<v-icon
            >mdi-check-all</v-icon
          >
          按钮来全选/全不选。下面的两个选择栏可以选择对应的版本/歌曲分类，选择后可以点击选择栏内右侧的<v-icon
            >mdi-close</v-icon
          >
          按钮来清除对应的筛选。
        </p>
        <p>
          接下来是“选择分数表列”选择栏，可以在这里选择各种分数表列是否显示（如：可以取消勾选“相对难度”复选框来隐藏相对难度列）。点击右侧的<v-icon
            color="orange"
            >mdi-content-save</v-icon
          >
          按钮可以把当前的分数表列设置保存为默认值，这样取消勾选“使用高级设置”甚至刷新页面后，分数表列设置都会保留。左侧的<v-icon
            >mdi-refresh</v-icon
          >
          按钮可以把分数表列设置重置为设置好的默认值。
        </p>
        <p>
          最后是“使用暗色主题”复选框，勾选后本网页将呈现黑色背景，保护眼睛。本设置也会在取消勾选“使用高级设置”甚至刷新页面后保留。
        </p>
        <h2 class="text--primary">成绩表格</h2>
        <p>
          这里是呈现所有成绩条目的位置。某些列有特殊功能，请好好利用。请注意，所有DX2021谱面都在“DX
          2021”标签内，所以当筛选或搜索显示“没有符合条件的条目”时，请确认要找的条目是否在“DX
          2021”标签内。
        </p>
        <h3 class="text--primary">“排名”表列</h3>
        <p>
          此处的排名为当前条目的DX
          rating在所有当前分类的成绩之间（不论是否被筛选）的排名。
        </p>
        <h3 class="text--primary">“乐曲名”表列</h3>
        <p>
          DX谱面将在乐曲名之前显示蓝色“DX”字样。乐曲名之后会显示FC/AP标记和FS/FDX标记（如果达成）。鼠标浮动在乐曲名上（移动端需要点击一下乐曲名的位置）将会弹出显示歌曲的详细信息，包括id、曲师、版本、分类和bpm。
        </p>
        <h3 class="text--primary">“难度”表列</h3>
        <p>
          鼠标浮动在难度色块上（移动端需要点击一下难度色块的位置）将会弹出显示当前谱面难度的详细信息，包括谱师和各类音符总数。
        </p>
        <h3 class="text--primary">“DX Rating”表列</h3>
        <p>
          如果当前条目在b40内，DX
          Rating数字将呈现绿色。如果当前条目没有达成当前谱面的最高Rating（即没有SSS+），鼠标浮动在DX
          Rating数字上（移动端需要点击一下DX
          Rating数字的位置）将显示当前谱面的部分后续Rating及对应的分数线，格式为“Rating(+Rating变化)：分数线(+分数变化)”。注意这里的“Rating变化”指相对于当前条目的Rating变化，而不是相对b40“地板”的变化（即：不一定是“达成这个分数就能加多少总Rating”）。
        </p>
        <h3 class="text--primary">“相对难度”表列</h3>
        <p>
          相对难度表示当前谱面达成SSS的人数占所有游玩过该谱面人数的比例，在所有同难度标级谱面（旧谱和DX2021谱二者均包括在内）中的排名。鼠标浮动在相对难度色块上（移动端需要点击一下相对难度色块的位置）可以查看更多详细信息。
        </p>
        <h3 class="text--primary">“DX分数比例”表列</h3>
        <p>
          该表列显示当前条目的DX分数占该谱面总DX分数的比例。如果能够达成DX+中的至少一个四角星（即DX分数比例在85%以上），DX分数比例数字右侧将显示达成的四角星数。鼠标浮动在DX分数比例数字或色块上（移动端需要点击一下对应位置）可以显示更多详细信息。
        </p>
        <h3 class="text--primary">“操作”表列</h3>
        <p>
          该表列包含三个按钮：封面<v-icon>mdi-image-outline</v-icon>按钮、编辑<v-icon>mdi-pencil-box-outline</v-icon>
          按钮及计算器<v-icon>mdi-calculator-variant-outline</v-icon>
          按钮。
        </p>
        <p>
          点击封面<v-icon>mdi-image-outline</v-icon>
          按钮可以查看当前曲目的封面。
        </p>
        <p>
          点击编辑<v-icon>mdi-pencil-box-outline</v-icon>
          按钮可以在弹窗中手动修改单个条目的成绩（如出勤时刷分后可以通过编辑<v-icon
            >mdi-pencil-box-outline</v-icon
          >
          按钮更改到最新分数），但FC/AP图标、FS/FDX图标和DX分数等其他信息均不会更改。注意通过“解锁全曲”按钮接锁的曲目不能通过编辑<v-icon
            >mdi-pencil-box-outline</v-icon
          >
          按钮编辑。
        </p>
        <p>
          计算器<v-icon>mdi-calculator-variant-outline</v-icon>
          按钮可以将当前条目的信息导入“计算工具”按钮中的计算器，并立即打开计算工具页面。具体请查看“计算工具”按钮的说明。
        </p>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import watchVisible from '../plugins/watchVisible';
export default {
  data: () => {
    return { visible: false };
  },
  watch: {
    visible: watchVisible("visible", "Tutorial"),
  },
};
</script>
