var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-sheet',{staticStyle:{"display":"box","margin":"0px 16px"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(" 按连击情况筛选 "),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){_vm.fc_filter.length === _vm.fc_filter_items.length ? (_vm.fc_filter = []) : (_vm.fc_filter = _vm.fc_filter_items.map(function (i) { return i.value; }))}}},[_vm._v("mdi-check-all")])],1)],1),_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"8"}},[_c('v-slide-group',{staticClass:"ml-2 py-2",attrs:{"multiple":"","show-arrows":""},model:{value:(_vm.fc_filter),callback:function ($$v) {_vm.fc_filter=$$v},expression:"fc_filter"}},_vm._l((_vm.fc_filter_items),function(item,key){return _c('v-slide-item',{key:key,staticClass:"mr-2",attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{attrs:{"color":_vm.getFC(item.value),"outlined":!active,"dark":""},on:{"click":toggle}},[_vm._v(_vm._s(item.text))])]}}],null,true)})}),1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(" 按难度筛选 "),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){_vm.level_filter.length === _vm.level_filter_items.length ? (_vm.level_filter = []) : (_vm.level_filter = _vm.level_filter_items.map(function (i) { return i.value; }))}}},[_vm._v("mdi-check-all")])],1)],1),_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"8"}},[_c('v-slide-group',{staticClass:"ml-2 py-2",attrs:{"multiple":"","show-arrows":""},model:{value:(_vm.level_filter),callback:function ($$v) {_vm.level_filter=$$v},expression:"level_filter"}},_vm._l((_vm.level_filter_items),function(item,key){return _c('v-slide-item',{key:key,staticClass:"mr-2",attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{attrs:{"color":_vm.getLevel(item.value),"outlined":!active,"dark":""},on:{"click":toggle}},[_vm._v(_vm._s(item.text))])]}}],null,true)})}),1)],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"4"}},[_c('v-subheader',[_vm._v(" 按评级筛选 "),_c('v-icon',{staticClass:"ml-2",on:{"click":function($event){_vm.rate_filter.length === _vm.rate_filter_items.length ? (_vm.rate_filter = []) : (_vm.rate_filter = _vm.rate_filter_items.map(function (i) { return i.value; }))}}},[_vm._v("mdi-check-all")])],1)],1),_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"8"}},[_c('v-slide-group',{staticClass:"ml-2 py-2",attrs:{"multiple":"","show-arrows":""},model:{value:(_vm.rate_filter),callback:function ($$v) {_vm.rate_filter=$$v},expression:"rate_filter"}},_vm._l((_vm.rate_filter_items),function(item,key){return _c('v-slide-item',{key:key,staticClass:"mr-2",attrs:{"value":item.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-chip',{attrs:{"color":_vm.getRate(item.value),"outlined":!active,"dark":""},on:{"click":toggle}},[_vm._v(_vm._s(item.text))])]}}],null,true)})}),1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.versions,"label":"版本","clearable":"","hide-details":""},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.genres,"label":"歌曲类别","clearable":"","hide-details":""},model:{value:(_vm.genre),callback:function ($$v) {_vm.genre=$$v},expression:"genre"}})],1)],1),_c('v-col',{staticClass:"px-0 py-0",attrs:{"cols":"5"}},[_c('v-checkbox',{attrs:{"label":"使用暗色主题","hide-details":""},on:{"change":_vm.toggleDarkTheme},model:{value:(_vm.darkTheme),callback:function ($$v) {_vm.darkTheme=$$v},expression:"darkTheme"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }