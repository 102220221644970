var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":_vm.masOnly ? 600 : 1000,"fullscreen":_vm.$vuetify.breakpoint.mobile},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-3 mr-4"},'v-btn',attrs,false),on),[_vm._v("牌子查询")])]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-card',[_c('v-card-title',[_vm._v(" 极将神牌辅助查询工具 "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.visible = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('v-select',{attrs:{"items":_vm.versions,"label":"选择版本"},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}})],1),_c('v-col',{attrs:{"cols":6}},[_c('v-checkbox',{attrs:{"label":"仅查看Master"},model:{value:(_vm.masOnly),callback:function ($$v) {_vm.masOnly=$$v},expression:"masOnly"}})],1)],1),_c('div',[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]),_vm._v("=Full Combo "),_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]),_vm._v("=SSS "),_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]),_vm._v("=Full Sync DX "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]),_vm._v("=All Perfect ")],1),_c('div',{staticClass:"mt-2"},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]),_vm._v(_vm._s(_vm.total_1)+"/"+_vm._s(_vm.filtered_length)+" "),_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]),_vm._v(_vm._s(_vm.total_2)+"/"+_vm._s(_vm.filtered_length)+" "),_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]),_vm._v(_vm._s(_vm.total_4)+"/"+_vm._s(_vm.filtered_length)+" "),_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]),_vm._v(_vm._s(_vm.total_8)+"/"+_vm._s(_vm.filtered_length)+" ")],1),_c('v-data-table',{attrs:{"headers":_vm.masOnly ? _vm.headerMas : _vm.headers,"items":_vm.filtered,"sort-by":"mst_pq","sort-desc":"","mobile-breakpoint":_vm.masOnly ? 0 : 600},scopedSlots:_vm._u([{key:"item.bas_pq",fn:function(ref){
var item = ref.item;
return [(item.bas_pq & 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.bas_pq & 2)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.bas_pq & 4)?_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.bas_pq & 8)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.adv_pq",fn:function(ref){
var item = ref.item;
return [(item.adv_pq & 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.adv_pq & 2)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.adv_pq & 4)?_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.adv_pq & 8)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.exp_pq",fn:function(ref){
var item = ref.item;
return [(item.exp_pq & 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.exp_pq & 2)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.exp_pq & 4)?_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.exp_pq & 8)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.mst_pq",fn:function(ref){
var item = ref.item;
return [(item.mst_pq & 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.mst_pq & 2)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.mst_pq & 4)?_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.mst_pq & 8)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]):_vm._e()]}},{key:"item.rem_pq",fn:function(ref){
var item = ref.item;
return [(item.rem_pq === -1)?_c('span',[(item.rem_pq & 1)?_c('v-icon',{attrs:{"color":"grey"}},[_vm._v("mdi-close-circle")]):_vm._e()],1):_c('span',[(item.rem_pq & 1)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.rem_pq & 2)?_c('v-icon',{attrs:{"color":"blue"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.rem_pq & 4)?_c('v-icon',{attrs:{"color":"purple"}},[_vm._v("mdi-check-bold")]):_vm._e(),(item.rem_pq & 8)?_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-check-bold")]):_vm._e()],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }